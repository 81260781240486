import { ArrowBoxOut } from '@deepup/icons';
import { Button, Typography, Stack, Link, CircularProgress } from '@mui/material';
import { Box, styled } from '@mui/system';
import React, { useEffect } from 'react';

const TextLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: 'none',
  fontWeight: 'bold',
}));

const REDIRECT_URL = 'https://conmon.deepup.io/map/welcome';

export const Deprecated = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = REDIRECT_URL;
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Box
      alignItems="center"
      component="div"
      display="flex"
      flexDirection="column"
      position="relative"
      sx={{
        top: 100,
        height: 'calc(100vh - 200px)',
      }}
    >
      <Stack direction="row" p={4}>
        <Box component="div" display="flex" flexDirection="column" gap={1} py={8}>
          <Typography variant="h4">
            Cockpit doesn’t exist anymore.
            <br />
            Welcome to Construction Monitoring!
          </Typography>
          <Typography variant="body1">
            You will be automatically redirected to Construction Monitoring.
          </Typography>
          <Typography variant="body1">
            If the redirection does not work, you can access the page using this link:
          </Typography>
          <Typography variant="body1">
            <TextLink href={REDIRECT_URL}>conmon.deepup.io</TextLink>
          </Typography>
          <CircularProgress />
        </Box>
        <Box component="div">
          <img alt="Page was moved" src="/page_removed.png" />
          <Box component="div" display="flex" justifyContent="end" py={4}>
            <Button
              color="primary"
              component="a"
              href={REDIRECT_URL}
              size="medium"
              startIcon={<ArrowBoxOut fill="currentColor" height="24px" width="24px" />}
              target="_blank"
              variant="contained"
            >
              Go To Conmon
            </Button>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};
